import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { validateField } from '../utils/validations';
import renderInput from '../utils/renderInput';

const TypeformTemplate = ({ questions, formInfo, onSubmit }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [otherAnswers, setOtherAnswers] = useState({});
  const [files, setFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [gridAnswers, setGridAnswers] = useState({});

  const handleInputChange = (e, questionEntry) => {
    const { name, value, type, checked } = e.target;
    const question = questions.find(q => q.entry === questionEntry);
    if (!question) {
      console.error(`Question not found for entry: ${questionEntry}`);
      return;
    }
  
    setAnswers(prevAnswers => {
      let newValue;
  
      switch (type) {
        case 'checkbox':
          // Handle checkbox inputs
          const prevValues = prevAnswers[questionEntry] || [];
          newValue = checked
            ? [...prevValues, value]
            : prevValues.filter(v => v !== value);
          break;
          
        case 'radio':
          // Handle radio inputs
          newValue = value === 'Other' ? '__other_option__' : value;
          if (value !== 'Other') {
            setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
          }
          break;
          
        default:
          newValue = value;
      }
  
      // Validate the new value
      const validationError = validateField(question.analyzedType, newValue);
      setErrors(prevErrors => ({
        ...prevErrors,
        [questionEntry]: validationError
      }));
  
      // Update state with new value
      return {
        ...prevAnswers,
        [questionEntry]: newValue
      };
    });
  };
  
  const handleOtherInputChange = (e, questionEntry) => {
    const { value } = e.target;
    setOtherAnswers(prevOtherAnswers => ({
      ...prevOtherAnswers,
      [questionEntry]: value
    }));
    setAnswers(prevAnswers => {
      const currentAnswer = prevAnswers[questionEntry];
      if (Array.isArray(currentAnswer)) {
        if (!currentAnswer.includes('__other_option__')) {
          return { ...prevAnswers, [questionEntry]: [...currentAnswer, '__other_option__'] };
        }
      } else if (currentAnswer !== '__other_option__') {
        return { ...prevAnswers, [questionEntry]: '__other_option__' };
      }
      return prevAnswers;
    });
  };

  const handleMultipleChoiceGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const newGridAnswers = {
        ...prev,
        [rowEntry]: option
      };
      console.log(newGridAnswers);
      return newGridAnswers;
    });
  };

  const handleCheckboxGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const currentSelections = prev[rowEntry] || [];
      if (currentSelections.includes(option)) {
        return { ...prev, [rowEntry]: currentSelections.filter(item => item !== option) };
      } else {
        return { ...prev, [rowEntry]: [...currentSelections, option] };
      }
    });
    console.log(gridAnswers);
  };

  // const handleFileUpload = (e, questionEntry) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFiles(prev => ({ ...prev, [questionEntry]: file }));
  //     setAnswers(prevAnswers => ({
  //       ...prevAnswers,
  //       [questionEntry]: file.name
  //     }));
  //   }
  // }

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    const newErrors = {};
    let isValid = true;

    questions.forEach(question => {
      const answer = answers[question.entry];

      if (question.required) {
        if (question.type === 'multiple_choice_grid') {
          const isGridComplete = question.rows.every((row) => {
            return gridAnswers[row.entry] !== undefined;
          });
          if (!isGridComplete) {
            newErrors[question.entry] = 'Please select an option for each row';
            isValid = false;
          }
        } else if (question.type === 'checkbox-grid') {
          const isGridComplete = question.rows.every((row) => {
            return Array.isArray(gridAnswers[row.entry]) && gridAnswers[row.entry].length > 0;
          });
          if (!isGridComplete) {
            newErrors[question.entry] = 'Please select at least one option for each row';
            isValid = false;
          }
        }
        else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          newErrors[question.entry] = 'This question is required';
          isValid = false;
        }
      }

      // Validate email and phone number inputs
      if (answer && (question.analyzedType === 'email' || question.analyzedType === 'tel')) {
        const validationError = validateField(question.analyzedType, answer);
        if (validationError) {
          newErrors[question.entry] = validationError;
          isValid = false;
        }
      }
    });

    setErrors(newErrors);

    if (isValid) {
      setIsSubmitting(true);
      setErrors({});

      try {
        const finalAnswers = {};
        const submissionTimestamp = Date.now().toString();
        const payload = {
          ...finalAnswers,
          formId: formInfo.formId,
          fbzx: formInfo.fbzx,
          fvv: formInfo.fvv,
          pageHistory: formInfo.pageHistory,
          hud: formInfo.hud,
          partialResponse: JSON.stringify([null, null, formInfo.fbzx]),
          submissionTimestamp: submissionTimestamp,
        };
        Object.entries(answers).forEach(([questionId, answer]) => {
        if (Array.isArray(answer)) {
          // For checkbox and multiple choice questions
          const processedOptions = answer.map(option => option === 'Other' ? '__other_option__' : option)
                                        .filter(option => option !== 'Other');
          
          payload[questionId] = processedOptions;

          if (processedOptions.includes('__other_option__')) {
            payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
          }
        } else if (answer === 'Other' || answer === '__other_option__') {
          // For "other" options in radio
          payload[questionId] = '__other_option__';
          payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
        } else {
          // For all other question types
          payload[questionId] = answer;
        }
      });

      // Add grid answers
      Object.entries(gridAnswers).forEach(([rowEntry, value]) => {
        payload[rowEntry] = value;
      });

      // Convert array values to separate entries
      const finalPayload = {};
      Object.entries(payload).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (finalPayload[key]) {
              finalPayload[key] = [...finalPayload[key], item];
            } else {
              finalPayload[key] = [item];
            }
          });
        } else {
          finalPayload[key] = value;
        }
      });
  
        const success = await onSubmit(payload);

        if (success) {
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error('Form submission failed:', error);
        setErrors(prev => ({
          ...prev,
          submit: 'Form submission failed. Please try again.'
        }));
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Find the first invalid question and set it as current
      const firstInvalidIndex = questions.findIndex(question => newErrors[question.entry]);
      if (firstInvalidIndex !== -1) {
        setCurrentQuestionIndex(firstInvalidIndex);
      }
    }
  };

  const handleCheckboxChange = (e, questionEntry) => {
    const { value, checked } = e.target;
    setAnswers(prev => {
      const currentAnswers = Array.isArray(prev[questionEntry]) ? prev[questionEntry] : [];
      if (checked) {
        return { ...prev, [questionEntry]: [...currentAnswers, value] };
      } else {
        return { ...prev, [questionEntry]: currentAnswers.filter(item => item !== value) };
      }
    });
    if (value === 'Other' && !checked) {
      setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
    }
  };

  const handleNext = () => {
    const currentQuestion = questions[currentQuestionIndex];
    let isValid = true;
    let errorMessage = '';

    // Check if the question is required and answered
    if (currentQuestion.required) {
      const answer = answers[currentQuestion.entry];
      if (currentQuestion.type === 'multiple_choice_grid') {
        const isGridComplete = currentQuestion.rows.every((row) => {
          const rowEntry = row.entry || row;
          return gridAnswers[rowEntry] !== undefined;
        });

        if (!isGridComplete) {
          isValid = false;
          errorMessage = 'Please select an option for each row';
        }
      }
      else if (currentQuestion.type === 'time') {
        const timeAnswer = answers[currentQuestion.entry];
        console.log(timeAnswer);
        if (!timeAnswer) {
          isValid = false;
          errorMessage = 'Please enter a valid time (HH:MM AM/PM)';
        }
      }
      else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
        isValid = false;
        errorMessage = 'This question is required';
      }
    }

    // If it's valid so far and has a specific type (email or tel), validate it
    if (isValid && currentQuestion.analyzedType && (currentQuestion.analyzedType === 'email' || currentQuestion.analyzedType === 'tel')) {
      const validationError = validateField(currentQuestion.analyzedType, answers[currentQuestion.entry]);
      if (validationError) {
        isValid = false;
        errorMessage = validationError;
      }
    }

    if (currentQuestion.type === 'multiple_choice_grid') {
      const isGridComplete = currentQuestion.rows.every((row) => {
        const rowEntry = row.entry || row;
        return gridAnswers[rowEntry] !== undefined;
      });
  
      console.log('Is grid complete:', isGridComplete);
  
      if (!isGridComplete) {
        isValid = false;
        errorMessage = 'Please select an option for each row';
      }
    }

    if (!isValid) {
      setErrors(prev => ({ ...prev, [currentQuestion.entry]: errorMessage }));
    } else {
      setErrors(prev => ({ ...prev, [currentQuestion.entry]: null }));
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        handleSubmit();
      }
    }
  };
  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center p-4">
        <div className="bg-gray-50 rounded-xl border border-ng-100 p-8 max-w-lg w-full text-center">
          <h2 className="text-3xl font-bold mb-4 text-black">Thank you for your submission!</h2>
          <p className="text-black">Your response has been recorded.</p>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="min-h-screen bg-white flex items-center justify-center p-4">
      <div className="max-w-lg w-full">
        <h1 className="text-3xl font-bold mb-2 text-gray-800">{formInfo.title}</h1>
        {formInfo.description && (
          <>
            <p className="text-gray-600 mb-6">{formInfo.description}</p>
            <hr className="mb-6 border-gray-200" />
          </>
        )}
        <div className="bg-white rounded-lg border-2 p-8">
          <div className="mb-8">
            <div className="w-full bg-gray-300 rounded-full h-1.5 mb-2">
              <div
                className="bg-black h-1.5 rounded-full transition-all duration-300"
                style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
              ></div>
            </div>
            <p className="text-gray-400 text-sm">Question {currentQuestionIndex + 1} of {questions.length}</p>
          </div>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentQuestionIndex}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-3xl font-bold mb-6 text-black">
                {currentQuestion.question}
                {currentQuestion.required && <span className="text-red-500 ml-1">*</span>}
              </h2>
              {currentQuestion.mediaContent && currentQuestion.mediaContent.type === 'image' && (
                <div className="w-full mb-4 flex justify-center">
                  <img
                    src={currentQuestion.mediaContent.url}
                    alt={currentQuestion.mediaContent.alt}
                    className="max-w-full h-auto rounded-lg shadow-md object-contain"
                    style={{ maxHeight: '60vh' }}
                  />
                </div>
              )}
              {currentQuestion.mediaContent && currentQuestion.mediaContent.type === 'video' && (
                <div className="relative w-full pt-[56.25%]">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src={currentQuestion.mediaContent.youtubeUrl}
                    title={currentQuestion.mediaContent.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              {renderInput(currentQuestion,answers,handleInputChange,handleCheckboxChange,otherAnswers,handleOtherInputChange,gridAnswers,handleMultipleChoiceGridSelection,handleCheckboxGridSelection)}
              {errors[currentQuestion.entry] && (
                <p className="text-red-500 mt-2">{errors[currentQuestion.entry]}</p>
              )}
            </motion.div>
          </AnimatePresence>
          <div className="mt-8 flex justify-between">
            <button
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
              className="bg-white text-black font-semibold py-2 px-4 rounded-md border-2 hover:bg-gray-50 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              disabled={isSubmitting}
              className="bg-black text-white font-semibold py-2 px-4 rounded-md hover:bg-gray-950 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {currentQuestionIndex === questions.length - 1 ? (isSubmitting ? 'Submitting...' : 'Submit') : 'Next'}
            </button>
          </div>
          {errors.submit && (
            <p className="text-red-500 mt-4 text-center">{errors.submit}</p>
          )}
        </div>
        <p className="flex items-center justify-center font-400 mt-3">Powered by <a href="https://formlite.co" target="_blank" rel="noreferrer" className="text-footer-text-blue ml-1"> Formlite</a></p>
      </div>
    </div>
  );
};

export default TypeformTemplate;