import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useApi from '../utils/api';
import ClassicTemplate from './ClassicTemplate';
import TypeformTemplate from './TypeformTemplate';
import ConversationalTemplate from './ConversationalTemplate';
import { validateField } from '../utils/validations';
import Lottie from 'lottie-react';
import confettiAnimationData from './assets/confetti.json';

// Utility function to analyze question type
const analyzeQuestionType = (questionText) => {
  const lowerCaseText = questionText.toLowerCase();

  if (lowerCaseText.includes('email') || lowerCaseText.includes('e-mail')) {
    return 'email';
  }

  if (lowerCaseText.includes('phone') || lowerCaseText.includes('mobile') || lowerCaseText.includes('number')) {
    return 'tel';
  }

  return 'text';
};

const PublicForm = () => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { formId } = useParams();
  const [searchParams] = useSearchParams();
  const template = searchParams.get('template') || 'classic';
  const api = useApi();

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setLoading(true);

        const response = await api.get(`/fetch-form?formId=${formId}`);

        if (!response.data) {
          throw new Error('No data received from server');
        }

        // Analyze question types
        const analyzedQuestions = response.data.questions.map(question => ({
          ...question,
          analyzedType: analyzeQuestionType(question.question)
        }));

        setFormData({
          ...response.data,
          questions: analyzedQuestions
        });
      } catch (err) {
        console.error('Error in fetchFormData:', err);
        setError(`Failed to load form data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (formId) {
      fetchFormData();
    } else {
      setError('No form ID provided');
      setLoading(false);
    }
  }, [formId]);

  const handleSubmit = async (submissionData) => {
    try {
      const response = await api.post(`/submit-form/${formId}`, submissionData);

      if (response.data.success) {
        setIsSubmitted(true);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 4500);
        return true;
      } else {
        console.error('Form submission failed');
        setError('Form submission failed. Please try again.');
        return false;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(`Error submitting form: ${error.message}`);
      return false;
    }
  };

  const renderForm = () => {
    const props = {
      questions: formData.questions,
      formInfo: {
        formId: formData.formId,
        title: formData.title,
        description: formData.description,
        fbzx: formData.fbzx,
        fvv: formData.fvv,
        pageHistory: formData.pageHistory,
        hud: formData.hud
      },
      onSubmit: handleSubmit,
      validateField: validateField
    };

    switch (template) {
      case 'Classic':
        return <ClassicTemplate {...props} />;
      case 'Interactive':
        return <TypeformTemplate {...props} />;
      case 'conversational':
        return <ConversationalTemplate {...props} />;
      default:
        return <ClassicTemplate {...props} />;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-[100vh] text-gray-900 dark:text-gray-100 dark:bg-gray-950">
        <div>
          <h1 className="text-xl md:text-7xl font-bold flex items-center">
            L<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="animate-spin" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM13.6695 15.9999H10.3295L8.95053 17.8969L9.5044 19.6031C10.2897 19.8607 11.1286 20 12 20C12.8714 20 13.7103 19.8607 14.4956 19.6031L15.0485 17.8969L13.6695 15.9999ZM5.29354 10.8719L4.00222 11.8095L4 12C4 13.7297 4.54894 15.3312 5.4821 16.6397L7.39254 16.6399L8.71453 14.8199L7.68654 11.6499L5.29354 10.8719ZM18.7055 10.8719L16.3125 11.6499L15.2845 14.8199L16.6065 16.6399L18.5179 16.6397C19.4511 15.3312 20 13.7297 20 12L19.997 11.81L18.7055 10.8719ZM12 9.536L9.656 11.238L10.552 14H13.447L14.343 11.238L12 9.536ZM14.2914 4.33299L12.9995 5.27293V7.78993L15.6935 9.74693L17.9325 9.01993L18.4867 7.3168C17.467 5.90685 15.9988 4.84254 14.2914 4.33299ZM9.70757 4.33329C8.00021 4.84307 6.53216 5.90762 5.51261 7.31778L6.06653 9.01993L8.30554 9.74693L10.9995 7.78993V5.27293L9.70757 4.33329Z"></path>
            </svg>ading . . .
          </h1>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-xl font-semibold text-red-600">Error: {error}</div>
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-xl font-semibold text-gray-800">No form data available.</div>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-green-600 mb-4">Thank you for your submission!</h2>
          <p className="text-gray-600">Your response has been recorded.</p>
        </div>
        {showConfetti && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 9999,
          }}>
            <Lottie
              animationData={confettiAnimationData}
              loop={false}
              autoplay={true}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        {renderForm()}
      </div>
    </div>
  );
};

export default PublicForm;
