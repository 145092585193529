import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import {
  ClerkProvider,
  SignIn,
  SignUp,
  useUser,
  RedirectToSignIn
} from '@clerk/clerk-react';
import posthog from 'posthog-js';
import LandingPage from './components/LandingPage';
import DashboardLayout from './layouts/DashboardLayout';
import EnhancedGoogleForm from './EnhancedGoogleForm';
import PublicForm from './components/PublicForm';
import Navbar from './components/Navbar';
import ShortUrlRedirect from './components/ShortUrlRedirect';

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error("Missing Clerk Publishable Key");
}

// Initialize PostHog
posthog.init('phc_TU90rIudT1ixO6cKv7Sq6DWBppWt0gyiw0D5APVIHYH', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  capture_pageview: true,
  session_recording: {
    enabled: true,
    minimum_duration: 1000,
  },
  autocapture: true
});

const ProtectedRoute = ({ children }) => {
  const { isSignedIn } = useUser();

  if (!isSignedIn) {
    return <RedirectToSignIn />;
  }

  return children;
};

const AppContent = () => {
  const { isSignedIn, isLoaded, user } = useUser();
  const location = useLocation();

  useEffect(() => {
    if (isLoaded && user) {
      // Identify user in PostHog when they sign in
      posthog.identify(user.id, {
        email: user.primaryEmailAddress?.emailAddress,
        name: user.fullName,
      });
    }
  }, [isLoaded, user]);

  useEffect(() => {
    // Capture pageview on route change
    posthog.capture('$pageview', {
      $current_url: location.pathname,
    });
  }, [location]);

  return (
    <Routes>
      <Route path="/public-form/:formId" element={<PublicForm />} />
      <Route path="/s/:shortCode" element={<ShortUrlRedirect />} />
      <Route path="*" element={<MainLayout isSignedIn={isSignedIn} />} />
    </Routes>
  );
};

const MainLayout = ({ isSignedIn }) => {
  return (
    <>
      <Navbar isSignedIn={isSignedIn} />
      <Routes>
        <Route
          path="/"
          element={
            isSignedIn ? (
              <Navigate to="/dashboard" replace />
            ) : (
              <LandingPage />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/form/:formId"
          element={
            <ProtectedRoute>
              <EnhancedGoogleForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sign-up/*"
          element={
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
              <SignUp routing="path" path="/sign-up" />
            </div>
          }
        />
        <Route
          path="/sign-in/*"
          element={
            <div className="flex items-center justify-center min-h-screen bg-gray-100">
              <SignIn routing="path" path="/sign-in" />
            </div>
          }
        />
        {/* Catch-all route for handling SSO callbacks */}
        <Route path="*" element={<div>Processing authentication...</div>} />
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      appearance={{
        baseTheme: 'dark'
      }}
    >
      <Router>
        <AppContent />
      </Router>
    </ClerkProvider>
  );
};

export default App;