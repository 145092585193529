import React, { useState, useEffect,useRef } from 'react';
import { useParams, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import useApi from './utils/api';
import ClassicTemplate from './components/ClassicTemplate';
import TypeformTemplate from './components/TypeformTemplate';
import ConversationalTemplate from './components/ConversationalTemplate';
import { ClipboardIcon } from 'lucide-react';
import { Clipboard, Check, Loader2, Trash2 } from 'lucide-react';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';
import confettiAnimationData from './components/assets/confetti.json';

const EnhancedGoogleForm = () => {
  const location = useLocation();
  const { formId } = useParams();
  const [searchParams] = useSearchParams();
  const template = searchParams.get('template') || 'classic';
  const isEditing = searchParams.get('edit') === 'true';
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const api = useApi();

  const [formData, setFormData] = useState(null);
  const [enhancedFormData, setEnhancedFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [urls, setUrls] = useState({
    original: '',
    adminEnhanced: '',
    publicEnhanced: '',
    short: ''
  });
  const [templates, setTemplates] = useState(template);
  const [analytics, setAnalytics] = useState(null);
  const [copiedOriginal, setCopiedOriginal] = useState(false);
  const [copiedPublic, setCopiedPublic] = useState(false);
  const [saveLoad, setSaveLoad] = useState(false);
  const [editError,setEditError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [initialTemplate, setInitialTemplate] = useState(templates);
  const [Modal, setModal] = useState(false);
  const [formDetails, setFormDetails] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const confirmDelete = () => {
    handleDeleteForm(formId);
    setModal(false);
  };

  useEffect(() => {
    setHasChanged(templates !== initialTemplate);
  }, [templates, initialTemplate]);

  const handleCopy = async (text, setCopiedState, toastMessage) => {
    await navigator.clipboard.writeText(text);
    setCopiedState(true);
    toast.success(toastMessage);
    setTimeout(() => setCopiedState(false), 2000);
  };

  useEffect(()=>{
    const token = getToken();
    let timeoutId;
    const getDetails = async () => {
      try {
        const enhancedResponse = await api.get(`/enhanced-form/${formId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFormDetails(enhancedResponse.data);
      } catch (error) {
        console.error('Error fetching form details:', error);
      }
    };

    timeoutId = setTimeout(() => {getDetails();}, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [formId, getToken, setFormDetails]);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setLoading(true);
        const token = await getToken();

        if (location.state?.enhancedFormData && location.state?.originalFormData) {
          setEnhancedFormData(location.state.enhancedFormData);
          setFormData(location.state.originalFormData);
          setUrls({
            original: location.state.originalFormData.originalForm.url,
            adminEnhanced: location.state.enhancedFormData.enhancedForm.adminUrl,
            publicEnhanced: location.state.enhancedFormData.enhancedForm.publicUrl,
            short: `${window.location.origin}/s/${location.state.enhancedFormData.shortUrl.shortCode}`
          });
          setAnalytics(location.state.enhancedFormData.analytics);
        } else if (isEditing) {
          const enhancedResponse = await api.get(`/enhanced-form/${formId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setEnhancedFormData(enhancedResponse.data);

          const originalResponse = await api.get(`/fetch-form?formId=${enhancedResponse.data.originalForm.id}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setFormData(originalResponse.data);
            setUrls({
            original: enhancedResponse.data.originalForm.url,
            adminEnhanced: enhancedResponse.data.enhancedForm.adminUrl,
            publicEnhanced: enhancedResponse.data.enhancedForm.publicUrl,
            short: `${window.location.origin}/s/${enhancedResponse.data.shortUrl.shortCode}`
          });
          setAnalytics(enhancedResponse.data.analytics);
        } else {
          const response = await api.get(`/fetch-form?formId=${formId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setFormData(response.data);

          const adminEnhancedUrl = `${window.location.origin}/form/${formId}?template=${template}`;
          const publicEnhancedUrl = `${window.location.origin}/public-form/${formId}?template=${template}`;

          const saveResponse = await api.put('/update-enhanced-form', {
            originalForm: {
              url: response.data.originalForm.url,
              id: `e/${formId}`
            },
            enhancedForm: {
              adminUrl: adminEnhancedUrl,
              publicUrl: publicEnhancedUrl,
              id: formId
            },
            template,
            title: response.data.title,
            description: response.data.description,
          }, {
            headers: { Authorization: `Bearer ${token}` }
          });

          setEnhancedFormData(saveResponse.data.enhancedForm);
          setUrls({
            original: response.data.originalForm.url,
            adminEnhanced: adminEnhancedUrl,
            publicEnhanced: publicEnhancedUrl,
            short: `${window.location.origin}/s/${saveResponse.data.enhancedForm.shortUrl.shortCode}`
          });
          setAnalytics(saveResponse.data.analytics);
        }
      } catch (err) {
        console.error('Error in fetchFormData:', err);
        setError(`Failed to load form data: ${err.message}`);
        toast.error(`Failed to load form data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (formId) {
      fetchFormData();
    } else {
      setError('No form ID provided');
      setLoading(false);
      toast.error('No form ID provided');
    }
  }, [formId, isEditing, getToken, location.state]);

  const handleSaveTemplate = async () => {
    const adminEnhancedUrl = `${window.location.origin}/form/${formId}?template=${templates}`;
    const publicEnhancedUrl = `${window.location.origin}/public-form/${formId}?template=${templates}`;

    try {
      const token = await getToken();
      setSaveLoad(true);
      const response = await api.put('/update-enhanced-form', {
        originalForm: {
          url: urls.original,
          id: `e/${formId}`
        },
        enhancedForm: {
          adminUrl: adminEnhancedUrl,
          publicUrl: publicEnhancedUrl,
          id: formId
        },
        template: templates,
        title: formData.title,
        description: formData.description,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if(response.status === 200){
        setSuccess(true);
        setHasChanged(false);
        setInitialTemplate(templates);
        window.location.replace(adminEnhancedUrl);
      }
      setEnhancedFormData(response.data.enhancedForm);
      setUrls({
        ...urls,
        adminEnhanced: adminEnhancedUrl,
        publicEnhanced: publicEnhancedUrl
      });
      toast.success('Template saved successfully');
    } catch (err) {
      console.error('Error in handleSaveTemplate:', err);
      toast.error(`Failed to save template: ${err.message}`);
    }
    finally{
      setSaveLoad(false);
    }
  }

  const handleSubmit = async (submittedFormData) => {
    try {
      const token = await getToken();

      const response = await api.post(`/submit-form/${formId}`, {
        ...submittedFormData
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if(response.status === 200 ){
        toast.success('Form updated successfully');
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 4500);
        return true;
      }
    } catch (error) {
      console.error('Error updating form:', error);
      setEditError('Failed to update type');
      // setError('Failed to update form');
      toast.error(`Failed to update form: ${error.message}`);
    }
  };

  const handleDeleteForm = async (formId) => {
    try{

      const token = getToken();
        const enhancedFormId = formDetails._id;

        const response = await api.delete(`/delete-form/${enhancedFormId}`, {
          headers: {Authorization: `Bearer ${token}`}
        });
        if (response.data.success) {
          setDeleteSuccess(true);
          setTimeout(() => navigate('/dashboard'), 1000);
        } else {
          toast.error('Failed to delete form');
        }
    }
    catch(err){
      console.error('Error deleting form:', err);
      toast.error('Failed to delete form');
    }
  };

  const renderForm = () => {
    if (!formData || !formData.questions) {
      console.error('Form data or questions are missing:', formData);
      return <div>Error: Form data is incomplete. Please try again.</div>;
    }

    const props = {
      questions: formData.questions,
      formInfo: {
        formId: formData.originalForm.id.replace(/^e\//, ''),
        title: enhancedFormData ? enhancedFormData.title : formData.title,
        description: enhancedFormData ? enhancedFormData.description : formData.description,
        fbzx: formData.fbzx,
        fvv: formData.fvv,
        pageHistory: formData.pageHistory,
        hud: formData.hud
      },
      onSubmit: handleSubmit,
      isEditing: isEditing
    };

    switch (templates) {
      case 'Classic':
        return <ClassicTemplate {...props} />;
      case 'Interactive':
        return <TypeformTemplate {...props} />;
      case 'conversational':
        return <ConversationalTemplate {...props} />;
      default:
        return <div>Invalid template selected: {template}</div>;
    }
  };

  if (loading) {
    return <div className="text-center mt-10">Loading form...</div>;
  }

  if (error) {
    return <div className="text-center mt-10 text-red-600">Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      {showConfetti && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          zIndex: 9999,
        }}>
          <Lottie
            animationData={confettiAnimationData}
            loop={false}
            autoplay={true}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      )}
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row gap-8">
        <div className="w-full md:w-1/3">
          <div className="bg-white shadow-sm rounded-xl border border-ng-100 overflow-hidden">
            <div className="p-4 sm:p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Your public form URL</h2>
              <div className="mb-6 flex">
                <input
                  type="text"
                  value={urls.original}
                  readOnly
                  className="flex-grow p-2 border-y border-l border-gray-300 rounded-l-md text-sm bg-white"
                />
                <button
                  onClick={() => handleCopy(urls.original, setCopiedOriginal, 'Original URL copied to clipboard!')}
                  className={`p-2 rounded-r-md transition-colors duration-200 ${
                    copiedOriginal 
                      ? 'bg-white hover:bg-gray-100 text-black' 
                      : 'bg-white hover:bg-gray-100 text-black'
                  } border border-gray-300`}
                  aria-label={copiedOriginal ? 'Copied' : 'Copy'}
                >
                  {copiedOriginal ? (
                    <Check className="w-4 h-4 sm:w-5 sm:h-5" />
                  ) : (
                    <Clipboard className="w-4 h-4 sm:w-5 sm:h-5" />
                  )}
                </button>
              </div>
              <h2 className="text-lg font-semibold text-gray-900 mb-2">Selected template</h2>
              <div className="mb-6">
                <select
                  value={templates}
                  onChange={(e) => {setTemplates(e.target.value);setSuccess(false)}}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm bg-white"
                >
                  <option value="Classic">Classic template</option>
                  <option value="Interactive">Interactive template</option>
                </select>
              </div>
              <div className="text-sm text-gray-500 mb-4">
                Clicks: {formDetails?.shortUrl.clicks || 0} | Submissions: {formDetails?.submissions || 0}
              </div>
              <div className="flex space-x-2">
                <button
                  className={`flex-1 font-medium py-1.5 px-2 rounded flex items-center justify-center text-xs sm:text-xs md:text-sm lg:text-sm transition-all duration-200 ease-in-out h-8 sm:h-9 md:h-10 ${
                    hasChanged
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  }`}
                  onClick={handleSaveTemplate}
                  disabled={!hasChanged}
                >
                  {saveLoad ? (
                    <Loader2 className="w-4 h-4 md:w-5 md:h-5 mr-1 md:mr-2 animate-spin" />
                  ) : (
                    'Save changes'
                  )}
                </button>
                <button
                  onClick={() => handleCopy(urls.short, setCopiedPublic, 'Public link copied to clipboard!')}
                  className="flex-1 bg-white border border-gray-300 text-gray-700 font-medium py-1.5 px-2 rounded flex items-center justify-center text-xs sm:text-xs md:text-sm lg:text-sm transition-all duration-200 ease-in-out h-8 sm:h-9 md:h-10"
                >
                  {copiedPublic ? (
                    <Check className="w-4 h-4 md:w-5 md:h-5 mr-1 md:mr-2" />
                  ) : (
                    <Clipboard className="w-4 h-4 md:w-5 md:h-5 mr-1 md:mr-2" />
                  )}
                  Copy public link
                </button>
                <button
                  onClick={() => setModal(true)}
                  className="bg-red-600 border border-gray-300 text-white font-medium py-1.5 px-2 rounded flex items-center justify-center text-xs sm:text-xs md:text-sm lg:text-sm transition-all duration-200 ease-in-out h-8 sm:h-9 md:h-10 hover:bg-red-700"
                >
                  <Trash2 className="w-4 h-4 md:w-5 md:h-5" />
                </button>
                {Modal && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-xl">
                      <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                      <p className="mb-6">Are you sure you want to delete this form? This action cannot be undone.</p>
                      <div className="flex justify-end space-x-4">
                        <button
                          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                          onClick={() => setModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                          onClick={confirmDelete}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {editError &&
                <div className="text-red-600 text-xs sm:text-sm mt-2">{editError}</div>
              }
              {success &&
                <div className='text-green-500 text-xs sm:text-sm mt-2'>Updated successfully</div>
              }
              {deleteSuccess &&
                <div className='text-green-500 text-xs sm:text-sm mt-2'>Deleted successfully</div>
              }
            </div>
          </div>
        </div>

          <div className="w-full md:w-2/3 bg-white shadow-sm rounded-xl border border-ng-100 overflow-hidden">
            {/* <div className="p-6"> */}
              {renderForm()}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnhancedGoogleForm;
