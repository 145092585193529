import React from 'react';

const LinearScale = ({ question, answers, handleInputChange }) => {
  if (!question.options || question.options.length < 2) {
    return <div className="text-red-500">Invalid scale options</div>;
  }

  const renderLabel = (label) => {
    if (typeof label === 'object' && label !== null) {
      return label.value || '';
    }
    return label || '';
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="text-lg font-medium">{question.text}</div>
      <div className="flex flex-col items-center">
        <div className="flex justify-between w-full mb-2">
          {question.options.map((option, index) => (
            <label key={index} className="flex flex-col items-center cursor-pointer">
              <input
                type="radio"
                name={question.entry}
                value={option.value}
                checked={answers[question.entry] === option.value}
                onChange={(e) => handleInputChange(e, question.entry)}
                required={question.required}
                className="absolute opacity-0 w-10 h-10 cursor-pointer"
              />
              <div 
                className={`w-10 h-10 rounded-full border-2 flex items-center justify-center transition-colors ${
                  answers[question.entry] === option.value 
                    ? 'bg-black border-black' 
                    : 'border-gray-300 hover:bg-gray-100'
                }`}
              >
                <span className={`text-sm font-bold ${answers[question.entry] === option.value ? 'text-white' : 'text-gray-700'}`}>
                  {option.value}
                </span>
              </div>
            </label>
          ))}
        </div>
        <div className="flex justify-between w-full">
          <div className="text-sm">{renderLabel(question.rows[0])}</div>
          <div className="text-sm">{renderLabel(question.rows[1])}</div>
        </div>
      </div>
    </div>
  );
};

export default LinearScale;