import React from "react";
import CheckboxGrid from "../components/common/checkBoxGrid";
import LinearScale from "../components/common/LinearScale";
import MultipleChoiceGrid from "../components/common/MultipleChoiceGrid";
import TimeInput from "../components/common/TimeInput";

const renderInput = (question,answers,handleInputChange,handleCheckboxChange,otherAnswers,handleOtherInputChange,gridAnswers,handleMultipleChoiceGridSelection,handleCheckboxGridSelection) => {
    const inputClasses = "w-full px-4 py-2 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500";
    const labelClasses = "inline-flex items-center cursor-pointer mr-3";
    const radioCheckboxClasses = "form-radio h-5 w-5 text-blue-600 border-gray-300 focus:ring-blue-500";
    // console.log(question.question);
    // console.log(question.type);
    // console.log(question);
    switch (question.type) {
      case 'text':
      case 'short_answer':
        return (
          <input
            type={question.analyzedType === 'email' ? 'email' : question.analyzedType === 'tel' ? 'tel' : 'text'}
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            className={inputClasses}
            placeholder={
              question.analyzedType === 'email' ? 'Enter your email' :
                question.analyzedType === 'tel' ? 'Enter your phone number' :
                  'Short answer'
            }
          />
        );
      case 'textarea':
      case 'paragraph':
        return (
          <textarea
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            rows="4"
            className={inputClasses}
            placeholder="Long answer"
          ></textarea>
        );
      case 'select':
      case 'multiple_choice':

        return (
          <div className="space-y-2">
            {question.options.map((option) => (
              <label key={option.value} className="flex items-center space-x-3 cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    name={question.entry}
                    value={option.value}
                    checked={Array.isArray(answers[question.entry]) && answers[question.entry].includes(option.value)}
                    onChange={(e) => handleCheckboxChange(e, question.entry)}
                    className="appearance-none w-5 h-5 border border-gray-300 rounded focus:outline-none"
                  />
                  {Array.isArray(answers[question.entry]) && answers[question.entry].includes(option.value) && (
                    <svg className="absolute top-0 left-0 w-5 h-5" viewBox="0 0 20 20" fill="black">
                      <rect width="20" height="20" rx="2" />
                      <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </div>
                <span className="text-sm font-medium text-gray-700">{option.value}</span>
              </label>
            ))}
            {question.hasOtherOption && (
              <div className="mt-4">
                <label className="flex items-center space-x-3 cursor-pointer mb-2">
                  <div className="relative">
                    <input
                      type="checkbox"
                      name={question.entry}
                      value="Other"
                      checked={Array.isArray(answers[question.entry]) && answers[question.entry].includes('Other')}
                      onChange={(e) => handleCheckboxChange(e, question.entry)}
                      className="appearance-none w-5 h-5 border border-gray-300 rounded focus:outline-none"
                    />
                    {Array.isArray(answers[question.entry]) && answers[question.entry].includes('Other') && (
                      <svg className="absolute top-0 left-0 w-5 h-5" viewBox="0 0 20 20" fill="black">
                        <rect width="20" height="20" rx="2" />
                        <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </div>
                  <span className="text-sm font-medium text-gray-700">Other</span>
                </label>
                <input
                  type="text"
                  value={otherAnswers[question.entry] || ''}
                  onChange={(e) => handleOtherInputChange(e, question.entry)}
                  className="block w-full px-3 py-2 mt-1 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  disabled={!Array.isArray(answers[question.entry]) || !answers[question.entry].includes('Other')}
                  placeholder="Please specify other option"
                />
              </div>
            )}
          </div>
        );
      case 'radio':
        return (
          <div className="space-y-2">
            {question.options.map((option) => (
              <label key={option.value} className="flex items-center space-x-3 cursor-pointer">
                <div className="relative">
                  <input
                    type="radio"
                    name={question.entry}
                    value={option.value}
                    checked={answers[question.entry] === option.value}
                    onChange={(e) => handleInputChange(e, question.entry)}
                    required={question.required}
                    className="appearance-none w-5 h-5 border border-gray-300 rounded-full checked:border-black checked:border-2 focus:outline-none"
                  />
                  {answers[question.entry] === option.value && (
                    <svg className="absolute top-0 left-0 w-5 h-5" viewBox="0 0 20 20" fill="black">
                      <circle cx="10" cy="10" r="10" />
                      <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </div>
                <span className="text-sm font-medium text-gray-700">{option.value}</span>
              </label>
            ))}
            {question.hasOtherOption && (
              <div className="mt-2">
                <label className="flex items-center space-x-3 cursor-pointer">
                  <div className="relative">
                    <input
                      type="radio"
                      name={question.entry}
                      value="__other_option__"
                      checked={answers[question.entry] === '__other_option__'}
                      onChange={(e) => handleInputChange(e, question.entry)}
                      className="appearance-none w-5 h-5 border border-gray-300 rounded-full checked:border-black checked:border-2 focus:outline-none"
                    />
                    {answers[question.entry] === '__other_option__' && (
                      <svg className="absolute top-0 left-0 w-5 h-5" viewBox="0 0 20 20" fill="black">
                        <circle cx="10" cy="10" r="10" />
                        <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </div>
                  <span className="text-sm font-medium text-gray-700">Other</span>
                </label>
                <input
                  type="text"
                  value={otherAnswers[question.entry] || ''}
                  onChange={(e) => handleOtherInputChange(e, question.entry)}
                  className="block w-full px-3 py-2 mt-1 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-black focus:border-black"
                  disabled={answers[question.entry] !== '__other_option__'}
                  placeholder="Please specify"
                />
              </div>
            )}
          </div>
        );
      case 'checkboxes':
        return (
          <div className="space-y-2">
            {question.options.map((option) => (
              <label key={option.value} className={`${labelClasses} block`}>
                <input
                  type="checkbox"
                  name={question.entry}
                  value={option.value}
                  checked={(answers[question.entry] || []).includes(option.value)}
                  onChange={(e) => handleInputChange(e, question.entry)}
                  className={`${radioCheckboxClasses} mr-2`}
                />
                <span>{option.value}</span>
              </label>
            ))}
            {question.hasOtherOption && (
              <div className="mt-2">
                <label className={`${labelClasses} block`}>
                  <input
                    type="checkbox"
                    name={question.entry}
                    value="Other"
                    checked={(answers[question.entry] || []).includes('Other')}
                    onChange={(e) => handleInputChange(e, question.entry)}
                    className={`${radioCheckboxClasses} mr-2`}
                  />
                  <span>Other:</span>
                </label>
                <input
                  type="text"
                  value={otherAnswers[question.entry] || ''}
                  onChange={(e) => handleOtherInputChange(e, question.entry)}
                  className={`${inputClasses} mt-2`}
                  disabled={!answers[question.entry] || !answers[question.entry].includes('Other')}
                  placeholder="Specify other option"
                />
              </div>
            )}
          </div>
        );
      case 'dropdown':
        return (
          <select
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            className={inputClasses}
          >
            <option value="">Select an option</option>
            {question.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        );
    //   case 'file_upload':
    //     return (
    //       <input
    //         type="file"
    //         name={question.entry}
    //         onChange={(e) => handleFileUpload(e, question.entry)}
    //         required={question.required}
    //         className={inputClasses}
    //         accept={question.fileTypes.join(',')}
    //       />
    //     );
      case 'linear_scale':
        return (
          <LinearScale
            question={question}
            answers={answers}
            handleInputChange={handleInputChange}
          />
        );
      case 'multiple_choice_grid':
        return (
          <div>
            <MultipleChoiceGrid
              question={{
                ...question,
                rows: question.rows.map(row => ({
                  entry: row.entry || row,
                  value: row.value || row
                })),
                options: question.options.map(option => ({
                  value: option.value || option
                }))
              }}
              gridAnswers={gridAnswers}
              handleMultipleChoiceGridSelection={handleMultipleChoiceGridSelection}
            />
          </div>
        );

        case 'checkbox-grid':
          // console.log('Rendering checkbox-grid:', question);
          return (
            <div>
              {/* <h3 className="text-lg font-semibold mb-2">{question.question}</h3> */}
              {question.rows && question.options ? (
                <CheckboxGrid
                  question={{
                    ...question,
                    rows: question.rows.map(row => ({
                      entry: row.entry || row,
                      value: row.value || row
                    })),
                    options: question.options.map(option => ({
                      value: option.value || option
                    }))
                  }}
                  gridAnswers={gridAnswers}
                  handleCheckboxGridSelection={handleCheckboxGridSelection}
                />
              ) : (
                <p className="text-red-500">Error: Invalid question structure for checkbox-grid</p>
              )}
            </div>
          );
      case 'date':
        return (
          <input
            type="date"
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            className={inputClasses}
          />
        );
      case 'time':
        return (
          <TimeInput
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            className={inputClasses}
          />
        );
      case 'section':
        return (
          <div className="mb-4">
            <h2 className="text-xl font-bold">{question.title}</h2>
            <p>{question.description}</p>
          </div>
        );
      default:
        return null;
    }
};

export default renderInput;