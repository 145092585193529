import React, { useState } from 'react';
import { validateField } from '../utils/validations';
import renderInput from '../utils/renderInput';

const ClassicTemplate = ({ questions, formInfo, onSubmit }) => {
  const [answers, setAnswers] = useState({});
  const [files, setFiles] = useState({});
  const [otherAnswers, setOtherAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [gridAnswers, setGridAnswers] = useState({});

  const handleInputChange = (e, questionEntry) => {
    const { name, value, type, checked } = e.target;
    const question = questions.find(q => q.entry === questionEntry);
    if (!question) {
      console.error(`Question not found for entry: ${questionEntry}`);
      return;
    }
  
    setAnswers(prevAnswers => {
      let newValue;
  
      switch (type) {
        case 'checkbox':
          const prevValues = prevAnswers[questionEntry] || [];
          newValue = checked
            ? [...prevValues, value]
            : prevValues.filter(v => v !== value);
          break;
          
        case 'radio':
          newValue = value === 'Other' ? '__other_option__' : value;
          if (value !== 'Other') {
            setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
          }
          break;
          
        default:
          newValue = value;
      }
      const validationError = validateField(question.analyzedType, newValue);
      setErrors(prevErrors => ({
        ...prevErrors,
        [questionEntry]: validationError
      }));
      return {
        ...prevAnswers,
        [questionEntry]: newValue
      };
    });
  };
  
  const handleOtherInputChange = (e, questionEntry) => {
    const { value } = e.target;
    setOtherAnswers(prevOtherAnswers => ({
      ...prevOtherAnswers,
      [questionEntry]: value
    }));
    setAnswers(prevAnswers => {
      const currentAnswer = prevAnswers[questionEntry];
      if (Array.isArray(currentAnswer)) {
        if (!currentAnswer.includes('__other_option__')) {
          return { ...prevAnswers, [questionEntry]: [...currentAnswer, '__other_option__'] };
        }
      } else if (currentAnswer !== '__other_option__') {
        return { ...prevAnswers, [questionEntry]: '__other_option__' };
      }
      return prevAnswers;
    });
  };

  const handleMultipleChoiceGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const newGridAnswers = {
        ...prev,
        [rowEntry]: option
      };
      console.log(newGridAnswers);
      return newGridAnswers;
    });
  };

  const handleCheckboxGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const currentSelections = prev[rowEntry] || [];
      if (currentSelections.includes(option)) {
        return { ...prev, [rowEntry]: currentSelections.filter(item => item !== option) };
      } else {
        return { ...prev, [rowEntry]: [...currentSelections, option] };
      }
    });
    console.log(gridAnswers);
  };

  // const handleFileUpload = (e, questionEntry) => {
  //   const file = e.target.files[0];
  //   if(file) {
  //     setFiles(prev => ({ ...prev, [questionEntry]: file }));
  //     setAnswers(prevAnswers => ({
  //       ...prevAnswers,
  //       [questionEntry]: file.name
  //     }));
  //   }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    let isValid = true;
  
    questions.forEach(question => {
      const answer = answers[question.entry];
  
      if (question.required) {
        if (question.type === 'multiple_choice_grid' || question.type === 'checkbox-grid') {
          const isGridComplete = question.rows.every((row, index) => {
            const key = `${question.entry}-${index}`;
            return gridAnswers[key] && (
              question.type === 'multiple_choice_grid' ?
              gridAnswers[key] !== undefined :
              gridAnswers[key].length > 0
            );
          });
          if (!isGridComplete) {
            newErrors[question.entry] = 'This question is required';
            isValid = false;
          }
        } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          newErrors[question.entry] = 'This question is required';
          isValid = false;
        }
      }
  
      // Validate email and phone number inputs
      if (answer && (question.analyzedType === 'email' || question.analyzedType === 'tel')) {
        const validationError = validateField(question.analyzedType, answer);
        if (validationError) {
          newErrors[question.entry] = validationError;
          isValid = false;
        }
      }
    });
  
    setErrors(newErrors);
  
    if (isValid) {
      setIsSubmitting(true);
      setErrors({});
  
      try {
        const finalAnswers = {};
        const submissionTimestamp = Date.now().toString();
        const payload = {
          ...finalAnswers,
          fbzx: formInfo.fbzx,
          fvv: formInfo.fvv,
          pageHistory: formInfo.pageHistory,
          hud: formInfo.hud,
          partialResponse: JSON.stringify([null, null, formInfo.fbzx]),
          submissionTimestamp: submissionTimestamp,
        };
        Object.entries(answers).forEach(([questionId, answer]) => {
          if (Array.isArray(answer)) {
            // For checkbox and multiple choice questions
            const processedOptions = answer.map(option => option === 'Other' ? '__other_option__' : option)
                                          .filter(option => option !== 'Other');
            
            payload[questionId] = processedOptions;
  
            if (processedOptions.includes('__other_option__')) {
              payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
            }
          } else if (answer === 'Other' || answer === '__other_option__') {
            // For "other" options in radio
            payload[questionId] = '__other_option__';
            payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
          } else {
            // For all other question types
            payload[questionId] = answer;
          }
        });
  
        // Add grid answers
        Object.entries(gridAnswers).forEach(([rowEntry, value]) => {
          payload[rowEntry] = value;
        });
  
        // Convert array values to separate entries
        const finalPayload = {};
        Object.entries(payload).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              if (finalPayload[key]) {
                finalPayload[key] = [...finalPayload[key], item];
              } else {
                finalPayload[key] = [item];
              }
            });
          } else {
            finalPayload[key] = value;
          }
        });
        
        const success = await onSubmit(payload);
  
        if (success) {
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error('Form submission failed:', error);
        setErrors(prev => ({
          ...prev,
          submit: 'Form submission failed. Please try again.'
        }));
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleCheckboxChange = (e, questionEntry) => {
    const { value, checked } = e.target;
    setAnswers(prev => {
      const currentAnswers = Array.isArray(prev[questionEntry]) ? prev[questionEntry] : [];
      if (checked) {
        return { ...prev, [questionEntry]: [...currentAnswers, value] };
      } else {
        return { ...prev, [questionEntry]: currentAnswers.filter(item => item !== value) };
      }
    });
    if (value === 'Other' && !checked) {
      setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
    }
  };

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-2xl w-full text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Thank you for your submission!</h2>
          <p className="text-gray-600">Your response has been recorded.</p>
        </div>
      </div>
    );
  }

  if (!questions || questions.length === 0 || !formInfo) {
    console.error('Missing required props in ClassicTemplate:', { questions, formInfo });
    return <div className="text-red-500">Error: Missing form data</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-md shadow-md p-8">
          <h1 className="text-3xl font-bold mb-2 text-gray-800">{formInfo.title}</h1>
          {formInfo.description && (
            <>
              <p className="text-gray-600 mb-6">{formInfo.description}</p>
              <hr className="mb-6 border-gray-200" />
            </>
          )}
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              {questions.map((question) => (
                <div key={question.id}>
                  <label className="block text-lg font-medium text-gray-700 mb-3">
                    {question.question}
                    {question.required && <span className="text-red-500 ml-1">*</span>}
                  </label>
                  {question.mediaContent && question.mediaContent.type === 'image' && (
                    <div className="w-full mb-4 flex justify-center">
                      <img
                        src={question.mediaContent.url}
                        alt={question.mediaContent.alt}
                        className="max-w-full h-auto rounded-lg shadow-md object-contain"
                        style={{ maxHeight: '60vh' }}
                      />
                    </div>
                  )}
                  {question.mediaContent && question.mediaContent.type === 'video' && (
                    <div className="relative w-full pt-[56.25%]">
                      <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src={question.mediaContent.youtubeUrl}
                        title={question.mediaContent.title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  )}
                  {renderInput(question,answers,handleInputChange,handleCheckboxChange,otherAnswers,handleOtherInputChange,gridAnswers,handleMultipleChoiceGridSelection,handleCheckboxGridSelection)}
                  {errors[question.entry] && (
                    <p className="text-red-500 text-sm mt-2">{errors[question.entry]}</p>
                  )}
                </div>
              ))}
              {/* {errors.submit && (
                <p className="text-red-500 text-sm mt-2">{errors.submit}</p>
              )} */}
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition duration-300 mt-8 text-lg font-semibold shadow-sm"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </form>
          <p className="flex items-center justify-center font-400 mt-3">Powered by <a href="https://formlite.co" target="_blank" rel="noreferrer" className="text-footer-text-blue ml-1"> Formlite</a></p>
        </div>

      </div>
    </div>
  );
};

export default ClassicTemplate;
