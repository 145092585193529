import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ConversationalTemplate = ({ questions, formInfo, onSubmit }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [otherAnswers, setOtherAnswers] = useState({});
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (currentQuestionIndex === 0) {
      setMessages([
        { type: 'bot', content: `Welcome to ${formInfo.title}!` },
        { type: 'bot', content: formInfo.description },
        { type: 'bot', content: renderQuestion(questions[currentQuestionIndex]) }
      ]);
    } else if (currentQuestionIndex < questions.length) {
      setMessages(prev => [...prev, { type: 'bot', content: renderQuestion(questions[currentQuestionIndex]) }]);
    }
  }, [currentQuestionIndex, questions, formInfo]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const renderQuestion = (question) => {
    let content = question.question;
    if (question.image) {
      content += `\n[Image: ${question.image.alt}]`;
    }
    if (question.type === 'radio' || question.type === 'checkbox') {
      content += '\nOptions:';
      question.options.forEach((option, index) => {
        content += `\n${index + 1}. ${option.value}`;
      });
      if (question.hasOtherOption) {
        content += '\n' + (question.options.length + 1) + '. Other';
      }
    }
    return content;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = () => {
    if (inputValue.trim() === '') return;

    const currentQuestion = questions[currentQuestionIndex];
    let answerValue = inputValue;

    if (currentQuestion.type === 'radio' || currentQuestion.type === 'checkbox') {
      const optionIndex = parseInt(inputValue) - 1;
      if (optionIndex >= 0 && optionIndex < currentQuestion.options.length) {
        answerValue = currentQuestion.options[optionIndex].value;
      } else if (currentQuestion.hasOtherOption && optionIndex === currentQuestion.options.length) {
        answerValue = 'Other';
        setOtherAnswers(prev => ({ ...prev, [currentQuestion.id]: '' }));
      } else {
        setMessages(prev => [...prev, { type: 'bot', content: 'Invalid option. Please try again.' }]);
        return;
      }
    }

    if (currentQuestion.type === 'checkbox') {
      answerValue = [answerValue];
    }

    setAnswers(prev => ({ ...prev, [currentQuestion.id]: answerValue }));
    setMessages(prev => [...prev, { type: 'user', content: inputValue }]);
    setInputValue('');

    if (answerValue === 'Other') {
      setMessages(prev => [...prev, { type: 'bot', content: 'Please specify your "Other" option:' }]);
    } else if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handleOtherInput = () => {
    const currentQuestion = questions[currentQuestionIndex];
    setOtherAnswers(prev => ({ ...prev, [currentQuestion.id]: inputValue }));
    setMessages(prev => [...prev, { type: 'user', content: inputValue }]);
    setInputValue('');
    
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setMessages(prev => [...prev, { type: 'bot', content: 'Thank you for your responses. Submitting your form...' }]);
    
    const finalAnswers = { ...answers };
    Object.keys(otherAnswers).forEach(questionId => {
      if (answers[questionId] === 'Other' || (Array.isArray(answers[questionId]) && answers[questionId].includes('Other'))) {
        finalAnswers[questionId] = Array.isArray(finalAnswers[questionId])
          ? finalAnswers[questionId].map(ans => ans === 'Other' ? otherAnswers[questionId] : ans)
          : otherAnswers[questionId];
      }
    });

    try {
      await onSubmit(finalAnswers);
      setMessages(prev => [...prev, { type: 'bot', content: 'Your form has been submitted successfully!' }]);
    } catch (error) {
      setMessages(prev => [...prev, { type: 'bot', content: 'There was an error submitting your form. Please try again.' }]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="h-screen bg-gray-100 flex flex-col">
      <div className="bg-white shadow-md p-4">
        <h1 className="text-2xl font-bold text-gray-800">{formInfo.title}</h1>
      </div>
      <div className="flex-grow overflow-y-auto p-4 space-y-4">
        <AnimatePresence>
          {messages.map((message, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={`mb-4 ${message.type === 'bot' ? 'text-left' : 'text-right'}`}
            >
              <div className={`inline-block p-3 rounded-lg ${
                message.type === 'bot' ? 'bg-white text-gray-800' : 'bg-blue-500 text-white'
              }`}>
                {message.content}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
        <div ref={messagesEndRef} />
      </div>
      {currentQuestionIndex < questions.length && !isSubmitting && (
        <div className="bg-white p-4">
          <div className="flex">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type your answer here..."
              className="flex-grow p-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={answers[questions[currentQuestionIndex].id] === 'Other' ? handleOtherInput : handleSendMessage}
              className="bg-blue-500 text-white px-4 py-2 rounded-r-lg hover:bg-blue-600 transition duration-300"
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConversationalTemplate;